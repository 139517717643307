.stack_ru
  .stack__text-block
    .base-caption-2
      max-width: toRem(542)
      margin-top: toRem(5)

    .base-title
      max-width: toRem(482)
      margin: 0 toRem(32) 0 0


.stack
  padding-top: toRem(600)
  padding-bottom: toRem(136)
  position: relative
  display: flex
  justify-content: flex-end

  &__img
    position: absolute
    z-index: 2
    top: calc(113/$layoutWidth * 100%)
    left: calc(508/$layoutWidth * 100%)
    width: calc(703 / $layoutWidth * 100%)
    pointer-events: none

  &__light-right
    position: absolute
    z-index: 1
    top: calc(-336/$layoutWidth * 100%)
    left: calc(617/$layoutWidth * 100%)
    width: calc(1051/$layoutWidth * 100%)
    height: calc(996/$layoutWidth * 100%)
    background: radial-gradient(32.51% 44.83% at 50% 50%, #665D59 4.96%, rgba(102, 93, 89, 0.69591) 32.32%, rgba(102, 93, 90, 0.2442) 64.5%, rgba(102, 93, 90, 0.14) 78.59%, rgba(102, 93, 90, 0) 100%)
    transform: rotate(37.14deg)

    img
      width: 100%

  &__light-left
    position: absolute
    z-index: 1
    background: radial-gradient(50% 50% at 50% 50%, #3B3C48 21.87%, rgba(59, 60, 72, 0.8) 38.54%, rgba(59, 60, 72, 0.3153) 66.15%, rgba(59, 60, 72, 0) 85.94%)
    top: calc(-40/$layoutWidth * 100%)
    left: calc(-21/$layoutWidth * 100%)
    width: calc(1083/$layoutWidth * 100%)
    height: calc(1007/$layoutWidth * 100%)
    transform: rotate(-15deg)

  &__wrapper
    position: relative
    z-index: 2
    width: toRem(1276)

    .base-headline
      margin-bottom: toRem(4)

  &__text-block
    display: flex
    justify-content: flex-start
    margin-bottom: toRem(71)

    .base-title
      margin: toRem(5) toRem(32) 0 0

    .base-caption-2
      max-width: toRem(552)

  &__tabs
    margin-bottom: toRem(48)

    .base-button
      border-color: $secondary-color
      background: transparent
      color: $secondary-color
      transition: all .2s ease-in-out

      &.inactive
        border-color: transparent
        background: rgba(0, 0, 0, 0.34)
        color: rgba(255, 255, 255, 0.33)

  &__stacks-grid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    row-gap: toRem(45)
    column-gap: toRem(32)

  &__tool-grid
    display: grid
    grid-template-columns: repeat(6, 1fr)
    gap: toRem(16)

.stack-card
  position: relative
  background: $substrate-dark
  border-radius: toRem(22)
  padding: toRem(120) toRem(0) toRem(33) toRem(40)
  min-height: toRem(264)

  &__text
    .base-caption-2
      line-height: 176%

  &__img
    position: absolute

  &:nth-child(1)
    .stack-card__img
      width: toRem(74)
      top: toRem(53)
      left: toRem(47)

  &:nth-child(2)
    .stack-card__img
      width: toRem(55)
      top: toRem(46)
      left: toRem(46)

  &:nth-child(3)
    .stack-card__img
      width: toRem(55)
      top: toRem(36)
      left: toRem(39)

  &:nth-child(4)
    .stack-card__img
      width: toRem(87)
      top: toRem(49)
      left: toRem(50)

  &:nth-child(5)
    .stack-card__img
      width: toRem(87)
      top: toRem(39)
      left: toRem(45)

  &:nth-child(6)
    .stack-card__img
      width: toRem(93)
      top: toRem(19)
      left: toRem(37)

  &:nth-child(7)
    .stack-card__img
      width: toRem(115)
      top: toRem(45)
      left: toRem(45)

  &:nth-child(8)
    .stack-card__img
      width: toRem(66)
      top: toRem(46)
      left: toRem(40)

  &:nth-child(9)
    .stack-card__img
      width: toRem(77)
      top: toRem(52)
      left: toRem(42)
