@mixin button()
  @include headlineBold()
  line-height: 222%
  color: $secondary-color
  padding: toEm(5, 18) toEm(40, 18)
  border: $secondary-color solid toEm(3, 18)

.base-button
  @include button()

  &_round
    border-radius: 100%
    padding: toEm(14, 18)

.base-headline
  @include headlineBold()
  line-height: 222%
  display: block

.base-title
  @include largeTitle()

.base-title-medium
  @include mediumTitle()

.base-caption
  @include captionMedium()

.base-caption-2
  @include caption2Medium()

.base-icon-button
  display: flex
  align-items: center
  justify-content: center
  width: toRem(60)
  height: toRem(60)
  border: toRem(3) solid $secondary-color
  border-radius: 100%
