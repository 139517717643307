$section-layout-width: 1712
$intellect-section-layout-width: 554
$intellect-img-layout-width: 210
$conscientiousness-section-layout-width: 617
$conscientiousness-img-layout-width: 228
$clarity-section-layout-width: 541
$clarity-img-layout-width: 332

.advantages_ru
  .advantage.clarity
    .base-caption-2
      line-height: 143% !important

.advantages
  margin-bottom: toRem(176)

  &__wrapper
    display: flex

  .advantage
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding-top: toRem(436)
    border-right: toRem(1) solid $dark_substr
    padding-bottom: toRem(21)

    &:last-child
      border-right: none

    &__img
      position: absolute

      img
        width: 100%

    &__text
      .base-headline
        color: $secondary-color
        margin-bottom: toRem(26)

    &.intellect
      width: calc($intellect-section-layout-width * 100%/$section-layout-width)
      padding-right: calc(103 * 100%/$section-layout-width)

      .advantage__img
        width: calc($intellect-img-layout-width * 100%/$intellect-section-layout-width)
        top: toRem(65)
        left: calc(109 * 100% / $intellect-section-layout-width)

      .base-caption-2:first-child
        margin-bottom: toRem(26)

    &.conscientiousness
      width: calc($conscientiousness-section-layout-width * 100%/$section-layout-width)
      padding-right: calc(98 * 100%/$section-layout-width)
      padding-left: calc(87 * 100%/$section-layout-width)

      .advantage__img
        width: calc($conscientiousness-img-layout-width * 100%/$conscientiousness-section-layout-width)
        top: toRem(99)
        left: calc(178 * 100% / $conscientiousness-section-layout-width)

    &.clarity
      width: calc($clarity-section-layout-width * 100%/$section-layout-width)
      padding-left: calc(76 * 100%/$section-layout-width)

      .base-caption-2
        line-height: 286%

      .advantage__img
        width: calc($clarity-img-layout-width * 100%/$clarity-section-layout-width)
        top: toRem(76)
        left: calc(106 * 100% / $clarity-section-layout-width)