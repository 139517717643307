.preloader 
  @apply fixed top-0 left-0 flex flex-col justify-between items-center bg-black
  z-index: 10000
  width: 100vw
  height: calc(var(--vh, 1vh) * 100)
  padding: toRem(107) toRem(300)
  transform: translate3d(0, 0, 0)
  transition: transform .5s ease

  @screen lg
    padding: 38/375 * 100vw 18/375 * 100vw

  @screen sm
    padding: 38/375 * 100vw 18/375 * 100vw

  &__img 
    width: 100%

  span 
    @apply font-light
    font-family: 'Inter-Light', sans-serif

    &.hide 
      color: transparent

  &__wrapper 
    display: flex
    position: relative
    overflow: hidden
    width: 100%
    padding-bottom: calc(100% / 6)

    &.moving 
      .preloader__letters 
        &:nth-child(odd)
          transform: translate3d(0, -80%, 0)

        &:nth-child(even)
          transform: translate3d(0, 0, 0)

  &__letters 
    position: absolute
    width: calc(100% / 6)
    height: 500%
    transition: transform 2s cubic-bezier(0, 0, 0.39, 1.28)

    @for $i from 1 through 6 
      &:nth-child(#{$i})
        left: ($i - 1) * (100% / 6)

    &:nth-child(odd)
      transform: translate3d(0, 0, 0)

    &:nth-child(even)
      transform: translate3d(0, -80%, 0)

  &__letter 
    width: 100%
    height: 20%
    padding: 6.27%

    & > div
      @include img-wrapper()

.preloader.exiting, .preloader.exited
  $vh100-: calc(var(--vh, 1vh) * -100)
  transform: translate3d(0, $vh100-, 0)
