.footer
  @apply relative flex justify-center z-10
  margin-bottom: toRem(31)

  @screen lg
    flex-direction: column-reverse
    align-items: center
    margin-bottom: calc(17/745 * var(--vh, 1vh) * 100)

  &.not-visible 
    visibility: hidden

    @screen lg
      display: none

  span 
    font-size: toRem(17)
    line-height: 265%
    @apply text-footer

    @screen lg
      color: #BCBFD6

    &.transparent 
      opacity: 0
      display: block

      @screen lg
        display: none

  &__logo
    position: relative
    width: toRem(173)
    height: toRem(26)

    @screen lg
      width: calc(153/745 * var(--vh, 1vh) * 100)
      height: calc(24/745 * var(--vh, 1vh) * 100)
