.text 
  @apply text-text font-medium
  font-size: toRem(21)
  line-height: 214%

  @screen lg
    font-size: calc(13/745 *  var(--vh, 1vh) * 100)

  &_sm-lh 
    line-height: 152%

  &_dark 
    @apply text-dark-grey

  &_reg 
    @apply font-normal

    @screen sm 
      font-size: 1.09rem
      line-height: 24/13 * 100%
