//.contacts
//  @apply fixed overflow-hidden w-full
//  height: calc(var(--vh, 1vh) * 100)
//  bottom: 0
//  left: 0
//  background: linear-gradient(167.49deg, #FFFFFF 42.27%, #D8DBED 69.78%, #C7CADB 124.91%)
//
//  @screen lg
//    background: linear-gradient(174.01deg, #FFFFFF 22.14%, #D8DBED 42.78%, #C7CADB 100.03%)
//
//  .container
//    width: 100%
//    height: 100%
//    @apply relative flex flex-col justify-between
//
//    @screen lg
//      justify-content: flex-end
//
//  &__bg
//    position: absolute
//    z-index: 1
//    width: toRem(2784)
//    height: toRem(1812)
//    left: -505 / 1920 * 100%
//    top: toRem(-821)
//
//    & > div
//      @include img-wrapper()
//
//    @screen lg
//      width: 900/345 * 1/2 * 100vw
//      height: 1123/345 * 1/2 * 100vw
//      left: 50%
//      top: -170/345 * 100vw
//      transform: translate(-50%, 0)
//
//    @screen sm
//      width: 900/345 * 100vw
//      height: 1123/345 * 100vw
//      top: -380/345 * 100vw
//      left: 43%
//
//  &__header
//    padding-top: toRem(31)
//
//    .contacts__logo
//      transform-origin: left top
//      position: relative
//      width: toRem(173)
//      height: toRem(26)
//      transform: scale(1.2)
//
//    @screen lg
//      position: absolute
//      z-index: 100
//      top: 0
//      left: toRem(24)
//
//  &__content
//    @apply relative flex justify-between
//    z-index: 10
//
//  &__left
//    @apply flex justify-between items-center w-full
//
//    @screen lg
//      flex-direction: column
//      align-items: flex-start
//      margin-bottom: calc(50/745*var(--vh, 1vh) * 100)
//
//  &__links
//    @screen sm
//      width: 100%
//
//  &__info
//    max-width: toRem(426)
//
//    @screen lg
//      max-width: toRem(540)
//      margin-bottom: calc(44/745*var(--vh, 1vh) * 100)
//
//    .title_h3
//      margin-bottom: toRem(24)
//      font-size: toRem(47)
//      line-height: 134%
//      color: $title-dark
//
//      @screen lg
//        font-size: calc(32/745 * 100 * var(--vh, 1vh))
//        line-height: 112%
//        margin-bottom: calc(12/745*var(--vh, 1vh) * 100)
//
//    p
//      @include p()
//      color: $title-dark
//
//  &__link-item
//    @apply text-dark-grey
//    background: transparent
//    margin-bottom: toRem(24)
//    min-width: toRem(322)
//    border: 1px solid #000000
//
//    @screen sm
//      min-width: 100%
//      margin-bottom: calc(16/745 * var(--vh, 1vh) * 100)
//      padding: .5em 1.75em
//
//    span
//      color: $title-dark
//
//      @screen sm
//        font-size: 1rem
//        line-height: 250%
//
//    &:hover
//      background: rgba(255, 255, 255, 0.5)
//
//    &:last-child
//      margin-bottom: 0
//
//    svg
//      @apply stroke-dark-grey
//      transform-origin: center center
//
//      @screen sm
//        transform: scale(.7)
//        stroke-width: 2px
//
//    &_1
//      svg
//        transform: rotate(-45deg)
//
//        @screen sm
//          transform: scale(.7) rotate(-45deg)
//
//    &_3
//      svg
//        transform: rotate(45deg)
//
//        @screen sm
//          transform: scale(.7) rotate(45deg)
//
//    &_4
//      svg
//        transform: rotate(180deg)
//
//        @screen sm
//          transform: scale(.7) rotate(180deg)
//
//  &__right
//    max-width: toRem(312)
//
//    p
//      @apply text-right

$bg-factor: 1.15

.contacts
  padding-top: toRem(207)
  padding-bottom: toRem(148)
  position: relative
  height: 100vh
  background: linear-gradient(167.49deg, #FFFFFF 42.27%, #D8DBED 69.78%, #C7CADB 124.91%)
  overflow: hidden

  &__bg
    position: absolute
    width: calc(3184/1920 * 100vw)
    height: min-content
    top: calc(-660/1920 * 1.15 * 100vw)
    right: calc(-696/1920 * 100vw)
    z-index: 1

    img
      width: 100%

  &__content
    height: 100%
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    justify-content: space-between

  .base-title
    color: $main-color-dark
    margin-bottom: toRem(15)

  &__footer
    @include flex-space-between()
    align-items: flex-end

    .base-headline
      color: #B2B8D0

  &__footer-left
    .base-caption-2
      margin-bottom: toRem(36)
      color: $main-color-dark

    .base-title
      line-height: 72%
