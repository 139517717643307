//.header
//  @apply relative flex justify-start items-start
//  z-index: 100
//
//  &__logo
//    position: relative
//    width: toRem(173)
//    height: toRem(26)
//    opacity: 1
//    transform: scale(1.2)
//    transform-origin: left top
//    transition: opacity .1s linear
//
//    @screen lg
//      width: calc(111/745 * var(--vh, 1vh) * 100)
//      max-width: toRem(200)
//
//    &.active
//      opacity: 0
//
//.burger
//  @apply absolute cursor-pointer
//  z-index: 101
//  top: toRem(-10)
//  right: toRem(94)
//  width: toRem(91)
//  height: toRem(40)
//  padding: toRem(10)
//
//  @screen lg
//    width: calc(44 / 745 * var(--vh, 1vh) * 100)
//    max-width: toRem(64)
//    min-width: toRem(40)
//    right: toRem(10)
//
//  @screen sm
//    width: toRem(44)
//    height: toRem(40)
//
//  &__upper-line, &__down-line, &__middle-line
//    @apply absolute bg-grey
//    width: calc(100% - toRem(20))
//    left: toRem(10)
//    height: toRem(2)
//
//  &__upper-line, &__down-line
//    transform: scale(1)
//    transition: transform .2s ease-in-out
//
//  &__upper-line
//    top: toRem(10)
//
//  &__down-line
//    bottom: toRem(10)
//
//  &__middle-line
//    top: 50%
//    transform: translateY(-50%)
//
//    &--upper, &--down
//      transition: transform .2s ease-in-out
//
//  &.active
//    .burger
//      &__upper-line, &__down-line
//        transform: scale(0)
//
//      &__middle-line
//        &--upper
//          transform-origin: center center
//          transform: rotate(30deg)
//
//        &--down
//          transform-origin: center center
//          transform: rotate(-30deg)

.header
  position: fixed
  top: 0
  left: 0
  z-index: 100
  width: 100%
  padding-top: toRem(24)
  padding-bottom: toRem(24)

  &.dark
    .header__logo
      svg
        fill: $main-color-dark

    .header__links
      a
        color: $main-color-dark

    .header__locale
      color: $main-color-dark

    .base-button
      border-color: $main-color-dark
      color: $main-color-dark

  header
    @include flex-between-center()

  &__logo
    display: flex
    justify-content: space-between
    width: toRem(189)
    height: toRem(28)

    svg
      fill: $white
      height: 100%

      &:nth-child(1)
        width: toRem(28)
      &:nth-child(2)
        width: toRem(28)
      &:nth-child(3)
        width: toRem(26)
      &:nth-child(4)
        width: toRem(26)
      &:nth-child(5)
        width: toRem(28)
      &:nth-child(6)
        width: toRem(28)

  &__links
    a
      @include headlineBold()
      line-height: 222%
      color: $white
      margin-right: toEm(50, 18)

      &:last-child
        margin-right: 0

  &__locale
    @include headlineBold()
    color: $text-color-accent
    margin-right: toRem(26)
