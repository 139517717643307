.stuffs
  padding: toRem(102) 0

  @screen lg
    padding: 0

  &__wrapper
    display: flex
    align-items: center
    max-width: toRem(1421)
    margin-left: 145 / 1712 * 100%
    flex-direction: column

    @screen lg
      flex-direction: column
      align-items: flex-start
      margin-left: 0

  &__left
    min-width: 474 / 1421 * 100%
    //margin-right: 108 / 1421 * 100%
    display: flex
    align-items: end

    @screen lg
      min-width: unset
      margin-right: 0
      flex-direction: column
      width: 100%
      align-items: start
      margin-bottom: toRem(32)

    //@screen md
    //  flex-direction: column

    .title_h3
      margin-bottom: toRem(32)

      @screen lg
        margin-bottom: calc(16/745 * var(--vh, 1vh) * 100)

    p
      @include p()

  &__text-wrapper
    //margin-top: toRem(-66)
    margin-bottom: toRem(166)

    @screen lg
      //margin: toRem(-46) 0 calc(32/745 * var(--vh, 1vh) * 100)
      margin-bottom: 0
      max-width: toRem(768)

  &__img
    position: relative
    width: toRem(551)
    height: toRem(717)
    margin: toRem(-156) 0 0 toRem(-66)

    div:first-child
      @include img-wrapper()

    @screen lg
      margin: 0 auto
      width: calc(303/745 * var(--vh, 1vh) * 100)
      height: calc(395/745 * var(--vh, 1vh) * 100)

  &__light
    position: absolute
    width: 150%
    height: 863/717 * 100%
    top: -33.33%
    left: -25%
    pointer-events: none

    & > div
      @include img-wrapper()

  &__right
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    min-width: 840 / 1421 * 100%

    @screen lg
      min-width: unset
      width: 100%

  &__slider
    padding-bottom: toRem(40) !important
    width: 100%
    overflow: unset!important

  .swiper-pagination
    position: absolute
    bottom: 0
    left: 50%
    width: toRem(98)
    height: toRem(8)
    transform: translate(-30%, 0)
    @include flex-center-space-between()

    .swiper-pagination-bullet
      width: toRem(8)
      height: toRem(8)
      border-radius: 100%
      background: rgba(255, 255, 255, 0.2)

      &-active
        background: rgba(255, 255, 255, 0.4)

.stuff
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  background: $substrate-dark-rgba
  width: 404 / 840 * 100%
  max-width: toRem(404)
  min-height: toRem(340)
  font-size: toRem(16)
  padding: toEm(26) toEm(26) toEm(38) toEm(43)
  margin-bottom: toRem(32)

  &:nth-child(n + 5)
    //margin-bottom: 0

    @screen lg
      margin-bottom: toRem(32)
      width: 193/375 * 100%
      min-height: calc(263/745 * var(--vh, 1vh) * 100)

  &__img
    position: relative

    &_1
      margin-top: toRem(35)
      width: toRem(117)
      height: toRem(44)

    &_2
      width: toRem(302)
      height: toRem(69)

    &_3
      width: toRem(164)
      height: toRem(49)

    &_2, &_3
      margin-top: toRem(22)

    &_4
      margin-left: toRem(-26)
      width: toRem(174)
      height: toRem(92)

    &_5
      margin-top: toRem(31)
      width: toRem(183)
      height: toRem(21)

    &_6
      margin-top: toRem(16)
      width: toRem(132)
      height: toRem(39)

  p
    @include p()
    line-height: 32 / 21 * 100%
    min-height: calc(1em * 4 * 32 / 21)

.stuff-slide
  flex: 1 0 auto
  max-width: 16rem
  min-height: 22rem
  background: rgba(0, 0, 0, 0.13)
  padding: 1.25rem 1.17rem 2rem

  @screen lg
    min-height: calc(263 / 745 * var(--vh, 1vh) * 100)

  @screen sm
    padding: toRem(39) toRem(16) toRem(24)
    min-height: toRem(263)
    width: toRem(193)
    max-width: toRem(193)

  &__wrapper
    @apply flex flex-col justify-between h-full

  &__img
    @apply flex items-center
    transform-origin: left top
    transform: scale(.7)
    min-height: 6.9rem

    & > div
      @include img-wrapper()

    @screen md
      transform: scale(.5)
      align-items: flex-start

    @screen sm
      transform: scale(1)

    &_1
      @screen sm
        width: toRem(92)
        height: toRem(34)

    &_2
      transform: scale(.95)
      min-height: 4.9rem

      @screen sm
        width: toRem(155)
        height: toRem(35)

    &_3
      @screen sm
        transform: scale(.6)

    &_4
      margin-left: -1.67rem

      @screen sm
        transform: scale(.6)
        margin-left: toRem(-15)
        margin-top: toRem(-15)

    &_5
      @screen sm
        transform: scale(.6)

    &_6
      @screen sm
        transform: scale(.6)

  &__text
    min-height: calc(2 * 1.52* toRem(21))

