.tool-card
  border-radius: toRem(17.3)
  width: toRem(200)
  height: toRem(200)
  @include flex-center()

  span
    font-family: 'Inter-Medium', sans-serif
    font-size: toRem(24)
    line-height: 167%
    font-weight: 500
