.case-preview
  position: relative
  padding: toRem(58) toRem(222) toRem(115) toRem(225)
  margin: 0 toRem(24) toRem(112)
  background: $case-substrate
  @include flex-space-between()
  align-items: flex-end

  &__short-info
    max-width: toRem(665)

    & > span
      display: inline-block
      @include navigation-text()
      margin-bottom: toRem(50)

    & > h3
      @include h3()
      margin-bottom: toRem(64)

  &__details
    display: flex

    & > div
      padding: toRem(30) toRem(46)
      background: $substrate-dark
      border-radius: toRem(22)

      &:first-child
        margin-right: toRem(16)

      & > span
        @include p-with-ls()

  &__light-1
    position: absolute
    z-index: 1
    top: calc(-209 / 676 * 100%)
    right: calc(-504/1872 * 100%)
    width: calc(1010/1872 * 100%)
    height: calc(1184/676 * 100%)
    transform: rotate(45deg)
    background: radial-gradient(47.42% 50% at 50% 50%, rgba(99, 89, 86, 0.9) 17.71%, rgba(99, 89, 86, 0.7042) 36.98%, rgba(99, 89, 86, 0.42) 56.77%, rgba(99, 89, 86, 0.22) 72.4%, rgba(99, 89, 86, 0.05) 87.5%, rgba(99, 89, 86, 0) 100%)

  &__light-2
    position: absolute
    z-index: 1
    top: calc(-670 / 676 * 100%)
    right: calc(180/1872 * 100%)
    width: calc(1046/1872 * 100%)
    height: calc(1533/676 * 100%)
    background: radial-gradient(50% 50% at 50% 50%, #504F5D 18.23%, rgba(80, 79, 93, 0.51) 49.48%, rgba(80, 79, 93, 0.14) 72.4%, rgba(80, 79, 93, 0.07) 84.38%, rgba(80, 79, 93, 0) 95.83%)
    transform: rotate(143.7deg)