@tailwind base
@tailwind components
@tailwind utilities

// Abstracts
@import "./abstracts/variables"
@import "./abstracts/functions"
@import "./abstracts/mixins"
@import "./abstracts/fonts"
@import "./abstracts/components"

// Fonts
@import "fonts"

// Common
@import "./common/container"
@import "./common/titles"
@import "./common/texts"
@import "./common/btns"

html
  font-size: 0.833vw

  //@screen lg
  //  font-size: 1rem

body 
  //@apply font-medium
  //font-family: 'Inter-Medium', sans-serif
  //scroll-behavior: smooth
  position: relative

html, body
  scrollbar-color: rgba(196,196,196, .2) $main-bg
  scrollbar-width: thin
  background: $main-bg
  overflow-x: hidden

.content-wrapper
  position: relative
  width: 100%
  overflow: hidden
  //height: calc(var(--vh, 1vh) * 100)
  z-index: 100
  background: $main-bg

.custom-scroll-bar
  scrollbar-width: thin

#__next
  //overscroll-behavior-y: contain

//.content
//  position: relative
//  width: 100%
//  overflow: hidden
//  background: $main-bg
//  will-change: transform
//  transform: translate3d(0, 0, 0)
//  transition: transform 1s ease-in-out
//  overscroll-behavior-y: contain
//  z-index: 10
//
//  @screen lg
//    transition-duration: .8s
//
//  @screen sm
//    transition-duration: .5s
//
//  &.entered, &.entering
//    $content-offset: calc(var(--vh, 1vh) * -100)
//    transform: translate3d(0, $content-offset, 0)

a 
  cursor: pointer

img
  pointer-events: none
  width: 100%

  &::selection
    background: transparent

.pre-open 
  overflow: hidden

// scroll

::-webkit-scrollbar
  width: 5px
  height: 8px
  background-color: $main-bg

::-webkit-scrollbar-thumb
  background: rgba(196,196,196, .2)
  border-radius: 33px

::-webkit-scrollbar-thumb:hover
  background: rgba(196,196,196, .3)

// modules
@import "./modules/preloader"
@import "./modules/header"
@import "./modules/menu"
@import "./modules/footer"
@import "./modules/partners"

// Components
@import "./components/case-preview"
@import "./components/client-card"
@import "./components/tool-card"
@import "./components/backcall-modal"
@import "./components/requisites-popup"

// Screens
@import "./screens/main"
@import "./screens/stuffs"
@import "./screens/advantages"
@import "./screens/releases"
//@import "./screens/reasons"
@import "./screens/team"
@import "./screens/contacts"
@import "./screens/case-page"
@import "./screens/cases"
@import "./screens/stack"
