.new-menu
  @apply absolute w-screen bg-dark-bg flex justify-center items-center flex-col
  z-index: 99
  height: 60vh
  top: -60vh
  left: 0
  padding-top: toRem(39)
  transform: translate3d(0, 0, 0)
  transition: transform .3s ease

  @screen sm 
    @apply items-start
    height: 21.33rem
    top: -21.33rem
    padding: 6.67rem 2rem 5.5rem

  &.entered, &.entering
    transform: translate3d(0, 100%, 0)

  span 
    @apply font-bold text-grey cursor-pointer
    font-family: 'Montserrat', sans-serif
    font-size: toRem(55)
    line-height: 118%
    margin-bottom: toRem(28)

    @screen lg
      font-size: 2rem
      line-height: 40/24 * 100%
      margin-bottom: 2.42rem

    &:last-child 
      margin-bottom: 0
