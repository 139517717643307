.callback-modal_ru
  .callback-modal__img
    top: calc(-120/990 * 100%)

.callback-modal
  position: fixed
  z-index: 1000
  width: 100%
  height: 100%
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: $main-color-dark
  display: flex
  justify-content: space-between

  &__left
    position: relative
    width: calc(653/1920 * 100%)
    padding: toRem(38) toRem(73) toRem(196) toRem(104)
    display: flex
    flex-direction: column
    justify-content: space-between

    & > .base-title
      margin-left: toRem(56)

  &__img
    position: absolute
    z-index: 1
    width: calc(612 * 1.65/653 * 100%)
    height: min-content
    top: calc(-80/990 * 100%)
    left: calc(-73 * 2.6/653 * 100%)
    pointer-events: none

    img
      width: 100%

  &__logo
    width: toRem(186)
    height: min-content

    img
      width: 100%

  &__right
    width: calc((1920 - 653)/1920 * 100%)
    height: 100%
    background: $dark_substr
    display: flex

  &__form
    width: calc(694/1267 * 100%)
    padding: toRem(114) toRem(64) toRem(32) toRem(81)
    border-right: 2px solid $main-color-dark

    & > .base-headline
      color: $white
      margin-bottom: toRem(36)

  &__privacy
    display: flex
    align-items: center
    margin-bottom: toRem(42)
    cursor: pointer

    a
      text-decoration-line: underline

  &__check-box
    width: toRem(32)
    height: toRem(32)
    border: toRem(3) solid #E2CDAE
    margin-right: toRem(16)
    padding: toRem(3)

  &__checked
    width: 100%

    img
      width: 100%

  &__btn
    width: 100%
    background: $secondary-color
    padding: toRem(16)
    display: flex
    justify-content: center
    align-items: center
    transition: background .15s ease

    .base-headline
      color: $main-color-dark
      transition: color .15s ease

    &:disabled
      background: $main-color-dark

      .base-headline
        color: $text-color-secondary

  &__description
    position: relative
    padding: toRem(114) toRem(96) toRem(32) toRem(63)
    width: calc((1267 - 694)/1267 * 100%)

    & > .base-button_round
      position: absolute
      top: toRem(28)
      right: toRem(104)

    & > .base-headline
      color: $white
      margin-bottom: toRem(45)

    & > .base-caption-2
      margin-bottom: toRem(65)

  &__contacts
    display: flex
    flex-direction: column

  &__link
    @include titleFont()
    font-size: toRem(22)
    font-weight: 700
    line-height: 145%
    color: $secondary-color
    margin-bottom: toRem(32)
    display: flex
    align-items: center

    &:last-child
      margin-bottom: 0

    .callback-modal__link-icon
      margin-right: toRem(15)
      width: toRem(32)

      img
        width: 100%


.base-input
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: toRem(23)

  &:last-child // delete
    margin-bottom: toRem(42)

  &_textarea
    margin-bottom: toRem(31)

  & > div
    width: 100%
    //overflow: hidden
    border-radius: toRem(16)
    display: flex
    height: min-content

  input, textarea
    @include headlineBold()
    text-transform: unset
    width: 100%
    line-height: 222%
    color: $white
    padding: toEm(15, 18) toEm(16, 18) toEm(17, 18)
    margin-top: toRem(-2)
    border-radius: toRem(16)
    background: #3E3E3E
    border: 2px solid #3E3E3E
    transition: border .15s ease

    &:focus
      outline: none

  input
    &.error
      border: 2px solid #950000

  textarea
    resize: none
