.client-card
  position: relative
  z-index: 2
  padding: toRem(42) toRem(34) toRem(30) toRem(30)
  border-radius: toRem(22)
  background: rgba(0, 0, 0, 0.13)
  max-width: toRem(550)

  & > span
    display: inline-block
    @include p-with-ls()
    margin-bottom: toRem(45)

  &__img
    height: toRem(88)
    margin-bottom: toRem(53)

  & > p
    @include description()
    margin-bottom: toRem(58)

  & > a
    @include p-with-ls()
    color: $main-color
