.title
  font-family: 'Montserrat', sans-serif

  &_h1
    @include h1()

  &_h2
    @include h2()

  &_h3
    @include h3()

  &_h4
    @include h4()

  &_m-dark 
    font-size: toRem(47)
    line-height: 134%
    @apply text-dark-grey

    @screen sm 
      font-size: 1.67rem
      line-height: 32/20 * 100%

  &_sm 
    font-size: toRem(40)
    line-height: 197.5%
    
    @screen sm 
      font-size: 1.67rem
      line-height: 32/20 * 100%
