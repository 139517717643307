//.team
//  padding: toRem(236) 0 toRem(120)
//
//  @screen lg
//    padding: 0
//
//  .container
//    position: relative
//
//    @screen lg
//      padding: 263/375*100vw toRem(24) 76/375*100vw
//      display: flex
//      flex-direction: column-reverse
//
//    @screen sm
//      padding: 463/375 * 100vw toRem(24) toRem(117)
//
//  &__back-title
//    position: relative
//    display: block
//    font-size: toRem(359)
//    line-height: 70%
//    width: 1757 / 1712 * 100%
//    height: 1em * 2 * 0.7
//    text-transform: lowercase
//
//    @screen lg
//      width: unset
//      height: unset
//      font-size: calc(32/745*var(--vh, 1vh) * 100)
//      margin-bottom: calc(11/745 * var(--vh, 1vh) * 100)
//
//    @screen sm
//      font-size: toRem(32)
//
//    span
//      position: absolute
//      z-index: -1
//      top: 0
//      left: 50%
//      transform: translate(-50%, 0)
//      text-align: center
//      color: #3E3E3E
//      transition: transform .5s ease-in
//
//      @screen lg
//        position: static
//        z-index: 1
//        top: unset
//        left: unset
//        transform: none
//        color: $title-light
//        line-height: 112%
//
//  &__text
//    position: absolute
//    width: toRem(830)
//    bottom: 0
//    left: 50%
//    transform: translate(calc(-50% + 35 / 830 * 100%), 0)
//    @include p()
//    font-size: toRem(37)
//    line-height: 143%
//    text-align: center
//    transition: transform .5s ease-in, opacity .5s ease-in
//    opacity: 0
//
//    @screen lg
//      position: static
//      left: unset
//      bottom: unset
//      opacity: 1
//      transform: none
//      width: auto
//      text-align: start
//
//    &_ru
//      width: toRem(930)
//
//      @screen lg
//        width: auto
//
//  &__person
//    position: absolute
//
//    & > div
//      @include img-wrapper()
//
//    &_1, &_2, &_3, &_4, &_5, &_6
//      transition: transform .5s ease-in, opacity .5s ease-in
//
//      @screen lg
//        opacity: 1
//        transform: none
//
//    &_1
//      width: toRem(273)
//      height: toRem(283)
//      top: 0
//      left: 0
//      transform: translate3d(621 / 274 * 100%, toRem(-293), 0)
//      opacity: 0
//
//      @screen lg
//        width: 272/375 * 1/2 * 100vw
//        height: 286/375 * 1/2 * 100vw
//        top: 10/375 * 100vw
//        left: 12%
//        transform: translate3d(0, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 272/375 * 3/4 * 100vw
//        height: 286/375 * 3/4 * 100vw
//        top: 58/375 * 100vw
//        left: -12%
//
//    &_2
//      width: toRem(318)
//      height: toRem(334)
//      top: 0
//      left: 0
//      transform: translate3d(633 / 255 * 100%, toRem(-355), 0)
//      opacity: 0
//
//      @screen lg
//        width: 212/375 * 1/2 * 100vw
//        height: 223/375 * 1/2 * 100vw
//        top: 40/375 * 100vw
//        left: 24%
//        transform: translate3d(50%, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 212/375 * 3/4 * 100vw
//        height: 223/375 * 3/4 * 100vw
//        top: 115/375 * 100vw
//        left: 3.5%
//
//    &_3
//      width: toRem(267)
//      height:  toRem(266)
//      top: 0
//      left: 0
//      transform: translate3d(871 / 268 * 100%, toRem(-277), 0)
//      opacity: 0
//
//      @screen lg
//        width: 231/375 * 1/2 * 100vw
//        height: 230/375 * 1/2 * 100vw
//        top: 55/375 * 100vw
//        left: 45%
//        transform: translate3d(50%, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 231/375 * 3/4 * 100vw
//        height: 230/375 * 3/4 * 100vw
//        top: 146/375 * 100vw
//        left: 33%
//
//    &_4
//      width:  toRem(266)
//      height: toRem(288)
//      top: 0
//      left: 0
//      transform: translate3d(562 / 266 * 100%, toRem(-79), 0)
//      opacity: 0
//
//      @screen lg
//        width: 195/375 * 1/2 * 100vw
//        height: 211/375 * 1/2 * 100vw
//        top: 105/375 * 100vw
//        left: 8.5%
//        transform: translate3d(50%, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 195/375 * 3/4 * 100vw
//        height: 211/375 * 3/4 * 100vw
//        top: 213/375 * 100vw
//        left: -21%
//
//    &_5
//      width: toRem(254)
//      height: toRem(264)
//      top: 0
//      left: 0
//      transform: translate3d(759 / 254 * 100%, toRem(-2), 0)
//      opacity: 0
//
//      @screen lg
//        width: 195/375 * 1/2 * 100vw
//        height:  203/375 * 1/2 * 100vw
//        top: 130/375 * 100vw
//        left: 31%
//        transform: translate3d(50%, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 195/375 * 3/4 * 100vw
//        height: 203/375 * 3/4 * 100vw
//        top: 250/375 * 100vw
//        left: 12%
//
//    &_6
//      width:  toRem(262)
//      height: toRem(280)
//      top: 0
//      left: 0
//      transform: translate3d(924 / 262 * 100%, toRem(-54), 0)
//      opacity: 0
//
//      @screen lg
//        width: 188/375 * 1/2 * 100vw
//        height: 200/375 * 1/2 * 100vw
//        top: 155/375 * 100vw
//        left: 53%
//        transform: translate3d(50%, 0, 0) !important
//        opacity: 1
//
//      @screen sm
//        width: 188/375 * 3/4 * 100vw
//        height: 200/375 * 3/4 * 100vw
//        top: 292/375 * 100vw
//        left: 45%
//
//
//.team.entered, .team.entering
//  .team
//    &__person
//      &_1
//        transform: translate3d(271 / 274 * 100%, toRem(-62), 0)
//        opacity: 1
//
//      &_2
//        transform: translate3d(678 / 277 * 100%, toRem(-204), 0)
//        opacity: 1
//
//      &_3
//        transform: translate3d(1337  / 268 * 100%, toRem(-145), 0)
//        opacity: 1
//
//      &_4
//        transform: translate3d(420 / 266 * 100%, toRem(373), 0)
//        opacity: 1
//
//      &_5
//        transform: translate3d(991 / 254 * 100%, toRem(432), 0)
//        opacity: 1
//
//      &_6
//        transform: translate3d(1440  / 262 * 100%, toRem(322), 0)
//        opacity: 1
//
//      @screen lg
//        transform: none
//        opacity: 1
//
//    &__back-title
//      span
//        transform: translate3d(-50%, 0, 0)
//
//        @screen lg
//          transform: none
//
//    &__text
//      transform: translate3d(calc(-50% + 35 / 830 * 100%), toRem(-127), 0)
//      opacity: 1
//
//      @screen lg2
//        transform: translate3d(calc(-50% + 35 / 830 * 100%), toRem(-93), 0)
//
//      @screen lg
//        transform: none

.team_ru
  .team__text-block
    .base-caption-2
      max-width: toRem(884)


.team
  padding-top: toRem(111)
  padding-bottom: toRem(113)

  &__text-block
    position: relative
    z-index: 2
    margin-bottom: toRem(112)

    .base-caption-2
      max-width: toRem(784)
      margin-bottom: toRem(10)

  &__title-block
    display: flex
    align-items: flex-start

    .base-title
      margin-right: toRem(82)

    .base-caption-2
      margin-top: toRem(8)

  &__team-block
    position: relative
    padding: 0 toRem(48) toRem(61) toRem(292)

    &:after
      position: absolute
      z-index: -1
      right: 0
      left: 0
      bottom: 0
      content: ''
      width: 100%
      height: calc(360/612 * 100%)
      background: $dark_substr

  &__list
    display: flex
    justify-content: space-between


$memberCircleSize: 363
.member-card
  &__header
    position: relative
    width: toRem($memberCircleSize)
    height: toRem($memberCircleSize)
    border-radius: 100%
    margin-bottom: toRem(59)

  &__img
    position: absolute
    z-index: 2

  &__light
    position: absolute
    pointer-events: none

  .base-headline
    color: $white

  &:nth-child(1)
    .member-card__header
      background: $person-1-color

    .member-card__img
      top: calc(-37/$memberCircleSize * 100%)
      left: calc(43/$memberCircleSize * 100%)
      width: calc(339/$memberCircleSize * 100%)

    .member-card__light
      z-index: 1
      width: calc(772/$memberCircleSize * 100%)
      height: calc(854/$memberCircleSize * 100%)
      top: calc(-318/$memberCircleSize * 100%)
      left: calc(-25/$memberCircleSize * 100%)
      background: radial-gradient(50% 50% at 50% 50%, rgba(81, 72, 88, 0.8) 14.58%, rgba(81, 72, 88, 0.59375) 52.6%, rgba(81, 72, 88, 0) 100%)
      transform: rotate(25.88deg)

  &:nth-child(2)
    .member-card__header
      background: $person-2-color

    .member-card__img
      top: calc(-41/$memberCircleSize * 100%)
      left: calc(40/$memberCircleSize * 100%)
      width: calc(290/$memberCircleSize * 100%)
      transform: rotate(-2.8deg)

  &:nth-child(3)
    .member-card__header
      background: $person-3-color

    .member-card__img
      top: calc(-57/$memberCircleSize * 100%)
      left: calc(37/$memberCircleSize * 100%)
      width: calc(309/$memberCircleSize * 100%)

    .member-card__light
      z-index: 1
      width: calc(794/$memberCircleSize * 100%)
      height: calc(824/$memberCircleSize * 100%)
      top: calc(-340/$memberCircleSize * 100%)
      left: calc(-58/$memberCircleSize * 100%)
      background: radial-gradient(50% 50% at 50% 50%, rgba(76, 70, 65, 0.8) 20.31%, rgba(76, 70, 65, 0.348958) 71.91%, rgba(76, 70, 65, 0) 100%)
      transform: rotate(25.88deg)


