.partners
  @apply flex items-center justify-between flex-wrap
  z-index: 100

  &__img
    @apply flex justify-center
    position: relative

    & > div
      @include img-wrapper()

    &_1
      width: toRem(153)
      height: toRem(49)

      @screen lg
        width: 129/375 * 0.6 * 100vw
        height: 39/375 * 0.6 * 100vw

    &_2
      margin-top: toRem(12)
      width: toRem(190)
      height: toRem(21)

      @screen lg
        max-width: 156/375 * 0.6 * 100vw
        max-height: 18/375 * 0.6 * 100vw
        margin-top: toRem(18)

      @screen sm
        margin-top: toRem(6)

    &_3
      width: toRem(174)
      height: toRem(91)

      @screen lg
        width: 143/375 * 0.6 * 100vw
        height: 74/375 * 0.6 * 100vw
        //margin-left: toRem(-20)

    &_4
      margin-top: toRem(6)
      width: toRem(183)
      height: toRem(21)

      @screen lg
        width: 128/375 * 0.6 * 100vw
        height: 15/375 * 0.6 * 100vw
        margin-top: toRem(12)

      @screen sm
        margin-top: toRem(6)

    &_5
      width: toRem(216)
      height: toRem(50)

      @screen lg
        width: 176/375 * 0.6 * 100vw
        height: 41/375 * 0.6 * 100vw

    &_6
      width: toRem(146)
      height: toRem(56)

      @screen lg
        width: 120/375 * 0.6 * 100vw
        height: 47/375 * 0.6 * 100vw


  &__img-wrapper
    @screen lg
      @include flex-center()
      justify-content: center
      width: 30%

    &:nth-child(3n + 1)
      @screen lg
        display: flex
        justify-content: flex-start

    &:nth-child(3n + 3)
      @screen lg
        display: flex
        justify-content: flex-end

    &_1
      order: 1

    &_2
      order: 2

    &_3
      order: 3

      @screen lg
        order: 4
        justify-content: flex-start !important

    &_4
      order: 4

      @screen lg
        order: 3
        justify-content: flex-end !important

    &_5
      order: 5

    &_6
      order: 6
