//@mixin font($font_name, $file_name, $weight, $style)
//  @font-face
//    font-family: $font_name
//    font-display: swap
//    src: url("../../public/fonts/#{$file_name}.woff2") format("woff2"), url("../../public/fonts/#{$file_name}.woff") format("woff"), url("../../public/fonts/#{$file_name}.ttf") format("truetype")
//    font-weight: $weight
//    font-style: $style

@mixin flex-space-between()
  display: flex
  justify-content: space-between

@mixin flex-align-center()
  display: flex
  align-items: center

@mixin flex-center()
  display: flex
  align-items: center
  justify-content: center

@mixin flex-center-space-between()
  display: flex
  align-items: center
  justify-content: space-between

@mixin reset-link($color: $white) 
  color: $color
  text-decoration: none
  outline: none

@mixin p-bold()
  font-size: toRem(32)
  line-height: 56 / 32 * 100%
  color: $text-light
  font-family: 'Inter-Medium', sans-serif

  @screen lg
    font-size: calc(14/745 * var(--vh, 1vh) * 100)

  @screen sm
    font-size: calc(14/745 * 0.9 * var(--vh, 1vh) * 100)

@mixin p()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(21)
  line-height: 45 / 21 * 100%
  color: $text-light

  @screen lg
    font-size: calc(14/745 * var(--vh, 1vh) * 100)
    line-height: 186%

  @screen sm
    font-size: toRem(14)

@mixin p-with-ls()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(21)
  font-weight: 700
  line-height: 190%
  color: $title-light
  letter-spacing: .03em
  text-transform: uppercase

@mixin sub-title()
  font-size: toRem(18)
  line-height: 40 / 18 * 100%
  font-weight: 700
  letter-spacing: 0.03em
  text-transform: uppercase
  color: $text-light
  font-family: 'Inter-Bold', sans-serif

  @screen lg
    font-size: calc(12/745 * var(--vh, 1vh) * 100)

  @screen sm
    font-size: toRem(12)


@mixin h1()
  font-size: toRem(63)
  line-height: 80 / 63 * 100%
  font-weight: 700
  color: $title-light

  @screen lg
    font-size: calc(32/745 * var(--vh, 1vh) * 100)
    line-height: 112%

  @screen sm
    font-size: toRem(32)

@mixin h2()
  font-size: toRem(90)
  line-height: 107 / 90 * 100%
  font-weight: 700
  color: $title-light

  @screen lg
    font-size: calc(32/745 * var(--vh, 1vh) * 100)
    line-height: 112%

  @screen sm
    font-size: toRem(32)

@mixin h3()
  font-size: toRem(56)
  line-height: 80 / 56 * 100%
  font-weight: 700
  color: $title-light

  @screen lg
    font-size: calc(32/745 * var(--vh, 1vh) * 100)
    line-height: 112%

  @screen sm
    font-size: toRem(32)

@mixin h4()
  font-size: toRem(40)
  line-height: 197%
  font-weight: 700
  color: $title-light

  @screen lg
    font-size: calc(24/745 * var(--vh, 1vh) * 100)
    line-height: 150%

  @screen sm
    font-size: toRem(24)

@mixin navigation-text()
  font-size: toRem(26)
  line-height: 188%
  font-weight: 500
  color: $text-light

@mixin description()
  font-size: toRem(26)
  line-height: 173%
  font-weight: 500
  color: $text-light

@mixin img-wrapper()
  position: relative
  width: 100%
  height: 100%

@mixin flex-between-center()
  display: flex
  justify-content: space-between
  align-items: center
