.requisites-popup-substr
  position: absolute
  z-index: 1000
  width: 100%
  height: 100vh
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  @include flex-center()
  content-visibility: visible

.requisites-popup
  overflow: hidden
  position: absolute
  bottom: -100vh
  left: 0
  z-index: 1000
  width: toRem(485)
  background: #18171B
  border-radius: toRem(24)
  padding: toRem(13) toRem(30) toRem(358)

  span
    @include captionSmall()
    display: block

  &__img
    position: absolute
    width: 100%
    z-index: 0
    bottom: 0
    left: 0
    z-index: 0

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: toRem(8)

    h3
      @include smallTitle()

  &__btn
    cursor: pointer
    width: toRem(36)
    height: toRem(36)
    border-radius: 100%
    padding: toRem(8)
    border: toRem(2.25) solid $secondary-color

  &__address
    position: relative
    margin-bottom: toRem(14)

  &__accounts
    position: relative
    width: 95%
    margin-bottom: toRem(14)
    @include flex-space-between()

  &__bank-info
    position: relative
    margin-bottom: toRem(14)

  &__tax-info
    position: relative
    width: 90.5%
    @include flex-space-between()
    align-items: start

  &__buffer-save
    position: absolute
    bottom: 0
    left: 0
    background: rgba(24, 23, 27, 0.8)
    border-radius: toRem(14.9)
    padding: toRem(11.5) toRem(16.27)
    display: flex
    align-items: center

    div
      width: toRem(22)
      margin-right: toRem(11)

    span
      font-family: 'Inter-Medium', sans-serif
      font-weight: 500
      font-size: toRem(16)
      line-height: 216%
      color: $secondary-color

