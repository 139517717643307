.releases
  margin-bottom: toRem(265)

  > .base-headline
      margin-bottom: toRem(37)

  &__list
    display: flex
    justify-content: space-between
    gap: toRem(34)

  .release-card
    &.disabled
      .release-card__img
        filter: brightness(.4)
        transition: filter .2s ease

        &:hover
          filter: brightness(1)

    &__preview
      position: relative
      overflow: hidden
      border-radius: toRem(26)
      width: toRem(404)
      height: toRem(239)
      margin-bottom: toRem(29)

    &__blackout
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.2)

    &__icon
      position: absolute
      width: calc(36 * 100%/404)
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    &__platform
      position: absolute
      top: 0
      right: 0

    &__img
      width: 100%
      height: 100%

      img
        object-fit: contain


.release-modal
  position: fixed
  z-index: 1000
  width: 100%
  height: 100%
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  display: flex
  justify-content: flex-end

  .base-button
    position: fixed
    top: toRem(28)
    left: toRem(-27)
    transform: translate(-100%, 0)

  &__wrapper
    position: relative
    width: calc(1268/1920 * 100%)

  &__scroll-wrapper
    width: 100%
    max-height: 100%
    min-height: 100%
    overflow: auto
    background: #2F2F2F

  &__img
    width: 100%

  &__content
    padding: toRem(85) toRem(249) toRem(85) toRem(179)

    .base-caption
      @include captionLarge()

    .base-title-medium
      margin-bottom: toRem(51)
