.btn 
  @apply relative bg-dark-sub-rgba text-white flex items-center justify-between z-10
  font-size: 1rem
  padding: toEm(15) toEm(23) toEm(13) toEm(28)
  max-width: toRem(275)
  transition: background-color .1s ease

  &:hover 
    background-color: opacify($substrate-dark-rgba, 0.3)

  &__text
    @include p()
    color: #FFFFFF

  &__icon 
    @apply flex items-center

  svg 
    width: toEm(31)
    height: toEm(22)
    @apply stroke-white origin-center
