//.main
//  min-height: calc(var(--vh, 1vh) * 100)
//  padding-top: toRem(702)
//
//  @screen lg2
//    padding-top: toRem(843)
//
//  @screen lg
//    padding-top: calc(440/745 * var(--vh, 1vh) * 100)
//
//  @screen sm
//    padding-top: calc(440/745 * 0.8 * var(--vh, 1vh) * 100)
//
//  &__container
//    position: relative
//
//  &__wrapper
//    position: absolute
//    width: 100%
//    height: calc(var(--vh, 1vh) * 100)
//    top: 0
//    left: 0
//
//  .header
//    position: absolute
//    top: 0
//    left: 0
//    width: 100%
//    transform: translate3d(0, toRem(23), 0)
//    opacity: 1
//    transition: transform 1s ease-in-out, opacity 1s ease-in-out
//
//    @screen lg
//      transition-duration: .8s
//
//    @screen sm
//      transition-duration: .5s
//
//  &__body
//    $offset-body: calc(var(--vh, 1vh) * 50 + toRem(-284))
//    position: absolute
//    top: 0
//    left: 0
//    width: 100%
//    display: flex
//    align-items: center
//    transform-origin: center top
//    transform: translate3d(0, $offset-body, 0)
//    opacity: 1
//    transition: transform 1s ease-in-out, opacity 1s ease-in-out
//
//    @screen lg
//      $offset-body: calc(98/745 * var(--vh, 1vh) * 100)
//      flex-direction: column
//      transform: translate3d(0, $offset-body, 0)
//      transition-duration: .8s
//
//    @screen sm
//      $offset-body: calc( 98/745 * 0.9 * var(--vh, 1vh) * 100)
//      transition-duration: .5s
//      transform: translate3d(0, $offset-body, 0)
//
//  .partners
//    position: absolute
//    left: 0
//    bottom: 0
//    transform: translate3d(0, toRem(-25), 0)
//    opacity: 1
//    transition: transform 1s ease-in-out, opacity 1s ease-in-out
//
//    @screen lg
//      transition-duration: .8s
//
//    @screen sm
//      transition-duration: .5s
//      transform: translate3d(0, toRem(-10), 0)
//
//  &__img
//    position: relative
//    width: toRem(500)
//    height: toRem(487)
//    margin: 0 calc(210 / 1712 * 100%) 0 calc(161 / 1712 * 100%)
//    transform: translate3d(0, 0, 0)
//    transition: transform 1s ease-in-out
//
//    & > div:first-child
//      @include img-wrapper()
//
//    @screen lg2
//      width: toRem(600)
//      height: toRem(584)
//
//    @screen lg
//      width: calc(239/745 * var(--vh, 1vh) * 100)
//      height: calc(231/745 * var(--vh, 1vh) * 100)
//      margin: 0 0 calc(32/745 * var(--vh, 1vh) * 100)
//      transition-duration: .8s
//
//    @screen sm
//      width: calc(239/745 * 0.9 * var(--vh, 1vh) * 100)
//      height: calc(231/745 * 0.9 * var(--vh, 1vh) * 100)
//      transition-duration: .5s
//
//  &__img-blur-left
//    position: absolute
//    width: 615/500 * 1.8 * 100%
//    height: 1090/487 * 1.5 * 100%
//    top: -473 / 487 * 1.5 * 100%
//    left: -216 * 2.4 / 500 * 100%
//    transform: rotate(143.7deg)
//    z-index: 100
//    pointer-events: none
//
//    & > div
//      @include img-wrapper()
//
//  &__img-blur-right
//    position: absolute
//    width: 527 * 2 / 500 * 100%
//    height: 1093 / 487 * 1.5 * 100%
//    top: -584 * 1.5/487 * 100%
//    left: 460/2.6 / 500 * 100%
//    transform: rotate(36.06deg)
//    z-index: 100
//    pointer-events: none
//
//    & > div
//      @include img-wrapper()
//
//  &__text-content
//    max-width: toRem(640)
//    margin-bottom: toRem(20)
//
//    @screen lg
//      max-width: 100%
//
//    span
//      @include sub-title()
//      margin-bottom: toRem(14)
//      display: block
//
//      @screen lg
//        margin-bottom: 0
//
//    h1
//      margin-bottom: toRem(37)
//
//      @screen lg
//        margin-bottom: calc(16/745 * var(--vh, 1vh) * 100)
//
//      @screen sm
//        margin-bottom: calc(16/745 * 0.9 * var(--vh, 1vh) * 100)
//
//    p
//      @include p-bold()
//
//    &_ru
//      max-width: toRem(710)
//
//      @screen lg
//        max-width: 100%
//
//  &__fire
//    position: absolute
//    z-index: 30
//    width: toRem(1438)
//    height: toRem(1172)
//    left: -14/1712 * 100%
//    top: toRem(-50)
//    transform: rotate(-15deg)
//    opacity: 0
//    transition: opacity 1s ease-in-out, transform 1s ease-in-out
//    pointer-events: none
//
//    @screen lg2
//      width: toRem(1726)
//      height: toRem(1407)
//      left: -130 / 1712 * 100%
//
//    @screen lg
//      width: calc(800/745 * var(--vh, 1vh) * 100)
//      height: calc(640/745 * var(--vh, 1vh) * 100)
//      left: 50vw
//      top: -4.0vh
//      transform: translate3d(-48%, 0, 0) rotate(-15deg)
//      transition-duration: .8s
//
//    @screen sm
//      transition-duration: .5s
//      width: calc(800/745 * 0.9 * var(--vh, 1vh) * 100)
//      height: calc(640/745 * 0.9 * var(--vh, 1vh) * 100)
//
//    & > div
//      @include img-wrapper()
//
//  &__stone
//    $start-left-offset: 400
//    $offset: calc(var(--vh, 1vh) * 50 + toRem(-337))
//    position: absolute
//    z-index: 50
//    width: toRem(778)
//    height: toRem(796)
//    top: 0
//    left: 0
//    transform-origin: center top
//    transform: translate3d($start-left-offset / 778 * 100%, $offset, 0) rotate(40.05deg)
//    transition: transform 1s ease-in-out
//    pointer-events: none
//
//    @screen lg2
//      $start-left-offset: 360
//      $offset: calc(var(--vh, 1vh) * 50 + toRem(-378))
//      width: toRem(934)
//      height: toRem(955.2)
//      transform: translate3d($start-left-offset / 778 * 100%, $offset, 0) rotate(40.05deg)
//
//    @screen lg
//      $stone-y-offset: calc(var(--vh, 1vh) * 1.5)
//      width: calc(380/745 * var(--vh, 1vh) * 100)
//      height: calc(385/745 * var(--vh, 1vh) * 100)
//      left: 50vw
//      transform-origin: center center
//      transform: translate3d(-48%, $stone-y-offset, 0) rotate(40.05deg)
//      transition-duration: .8s
//
//    @screen sm
//      transition-duration: .5s
//      width: calc(380/745 * 0.9 *  var(--vh, 1vh) * 100)
//      height: calc(385/745 * 0.9 *  var(--vh, 1vh) * 100)
//
//    & > div:not(.main__stone-blur)
//      @include img-wrapper()
//
//  &__stone-blur
//    position: absolute
//    width: 2.5 * 760 / 778 * 100%
//    height:  2.5 * 407 / 796 * 100%
//    top: toRem(-70)
//    left: -700 / 778 * 100%
//    transform: rotate(146.45deg)
//    opacity: 0
//    transition: opacity 1s ease-in-out
//    pointer-events: none
//
//    @screen lg
//      width: calc(900 * 1.5 / 745 * var(--vh, 1vh) * 100)
//      height: calc(481 * 1.5 / 745 * var(--vh, 1vh) * 100)
//      top: calc(var(--vh, 1vh) * -15)
//      left: 50vw
//      transition-duration: .8s
//      transform: translate3d(-63%, 0, 0) rotate(146.45deg)
//
//    @screen sm
//      transition-duration: .5s
//      width: calc(900 * 1.5 / 745 * 0.9 * var(--vh, 1vh) * 100)
//      height: calc(481 * 1.5 / 745 * 0.9 * var(--vh, 1vh) * 100)
//
//    & > div
//      @include img-wrapper()
//
//  &__lines
//    position: absolute
//    z-index: 40
//    width: toRem(1351)
//    height: toRem(527)
//    top: 0
//    left: 0
//    transform: translate3d(856 / 1351 * 100%, toRem(-400), 0) rotate(-30deg)
//    opacity: 0
//    transition: opacity 1s ease-in-out, transform 1s ease-in-out
//    pointer-events: none
//
//    @screen lg2
//      width: toRem(1622)
//      height: toRem(633)
//      transform: translate3d(856 / 1351 * 100%, toRem(-480), 0) rotate(-30deg)
//
//    @screen lg
//      width: calc(700/745 * var(--vh, 1vh) * 100)
//      height: calc(274/745 * var(--vh, 1vh) * 100)
//      left: 50vw
//      transition-duration: .8s
//
//    @screen sm
//      transition-duration: .5s
//      width: calc(700/745 * 0.9 * var(--vh, 1vh) * 100)
//      height: calc(274/745 * 0.9 * var(--vh, 1vh) * 100)
//
//    & > div
//      @include img-wrapper()
//
//.main.entered, .main.entering
//  .header
//    $header-y-offset: calc(toRem(23) + var(--vh, 1vh) * -70)
//    transform: translate3d(0, $header-y-offset, 0)
//    opacity: 0
//    pointer-events: none
//
//    @screen lg
//      transform: translate3d(0, toRem(23), 0)
//
//  .partners
//    $partners-y-offset: calc(toRem(-25) + var(--vh, 1vh) * -70)
//    transform: translate3d(0, $partners-y-offset, 0)
//    opacity: 0
//    left: 0
//    bottom: 0
//    pointer-events: none
//
//    @screen lg
//      $partners-y-offset: calc(var(--vh, 1vh) * 100 * 0.6)
//      transform: translate3d(-100%, $partners-y-offset, 0)
//
//  .main
//    &__stone
//      $end-left-offset: calc(500/ 778 * 100%)
//      transform: translate3d($end-left-offset, 0, 0)  rotate(0deg)
//
//      @screen lg2
//        $end-left-offset: calc(394/ 778 * 100%)
//        transform: translate3d($end-left-offset, 0, 0)  rotate(0deg)
//
//      @screen lg
//        $stone-y-offset: calc(var(--vh, 1vh) * 1.5)
//        transform: translate3d(-48%, $stone-y-offset, 0) rotate(10deg)
//
//    &__stone-blur
//      opacity: 1
//
//    &__fire
//      opacity: 1
//
//      @screen lg
//        transform: translate3d(-63%, 0, 0) rotate(-15deg)
//
//    &__body
//      $body-y-offset: calc(var(--vh, 1vh) * -20 + toRem(-284))
//      transform: translate3d(0, $body-y-offset, 0)
//      opacity: 0
//      pointer-events: none
//
//      @screen lg
//        $body-y-offset: calc(var(--vh, 1vh) * 60)
//        transform: translate3d(-100%, $body-y-offset, 0)
//
//      .main__img
//        $img-y-offset: calc(var(--vh, 1vh) * 10)
//        transform: translate3d(0, $img-y-offset, 0)
//        pointer-events: none
//
//        @screen lg
//          transform: translate3d(50%, 0, 0)
//
//    &__lines
//      opacity: 1
//      transform: translate3d(0, toRem(220), 0) rotate(-30deg)
//
//      @screen lg2
//        transform: translate3d(toRem(-170), toRem(275), 0) rotate(-30deg)
//
//      @screen lg
//        $lines-y-offset: calc(var(--vh, 1vh) * 10)
//        transform: translate3d(-50%, $lines-y-offset, 0) rotate(-35deg)
$substr-layout-width: 500
$substr-layout-height: 487
$light-color: rgba(176, 175, 233, 0.25)
$width-light-factor: 1.8
$height-light-factor: 1.5
$blur-value: 75px

.scroll-container
  .stone, .fire, .lines
    position: fixed
    will-change: transform
    pointer-events: none
    user-select: none

.scroll-container_ru
  .main
    &__text-block
      max-width: toRem(681)

      .base-title
        font-size: toRem(50)

      .base-caption
        font-size: toRem(26)

  .cases
   .case-card:nth-child(4)
     padding: toRem(49) toRem(21) toRem(59) toRem(21)

     .base-caption-2
       width: toRem(425)
.main
  position: relative
  width: 100%
  padding-bottom: 10%

  &__body
    position: relative
    padding-top: getAdaptiveByHeight(248)
    display: flex
    justify-content: center
    height: calc(var(--vh, 1vh) * 100)

  &__img
    position: relative
    margin: toRem(10) toRem(210) 0 0
    width: toRem(500)
    height: min-content

    img
      width: 100%

    .substr-light-1
      position: absolute
      //bottom: calc(-115 / $substr-layout-height * 100%)
      //left: calc(-353 / $substr-layout-width * 100%)
      //width: calc(615 / $substr-layout-width *  100%)
      //height: calc(1090 / $substr-layout-height * 100%)
      //background: rgba(176, 175, 233, 0.25)
      //border-radius: 100%
      //filter: blur($blur-value)
      //transform: rotate(143.7deg) translate3d(0, 0, 0)
      // стили для пнгшки
      position: absolute
      bottom: calc(-255 * $height-light-factor / $substr-layout-height * 100%)
      left: calc(-350 * $width-light-factor / $substr-layout-width * 100%)
      width: calc(615/$substr-layout-width * $width-light-factor * 100%)
      height: calc(1090/$substr-layout-height * $height-light-factor * 100%)
      transform: rotate(143.7deg)
      z-index: 50
      pointer-events: none

    .substr-light-2
      //position: absolute
      //z-index: 50
      //pointer-events: none
      //width: calc(527 / $substr-layout-width * 100%)
      //height: calc(1093 / $substr-layout-height * 100%)
      //bottom: calc( -8 /$substr-layout-height * 100%)
      //right: calc(-350 / $substr-layout-width * 100%)
      //transform: rotate(36.06deg) translate3d(0, 0, 0)
      //filter: blur($blur-value)
      //background: rgba(216, 215, 242, 0.2)
      // стили для пнгшки
      position: absolute
      z-index: 50
      pointer-events: none
      width: calc(527 * $width-light-factor / $substr-layout-width * 100%)
      height: calc(1093 * $height-light-factor / $substr-layout-height * 100%)
      bottom: calc( -200 * $height-light-factor/$substr-layout-height * 100%)
      right: calc(-300 * $width-light-factor/ $substr-layout-width * 100%)
      transform: rotate(36.06deg)

  &__text-block
    max-width: toRem(581)

    .base-headline
      margin-bottom: toRem(9)

    .base-title
      margin-bottom: toRem(32)

  &__btn
    position: absolute
    bottom: getAdaptiveByHeight(57)
    left: 0
    right: 0
    margin: 0 auto

    svg
      margin-top: toRem(2)
      width: toRem(28)
      height: toRem(14)
      stroke: $secondary-color