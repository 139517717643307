$base-fz: 16

$black: #000
$white: #fff

$title-light: #E9E9E9
$title-dark: #565656

$text-light: #A4A4A4
$footer-text-color: rgba(86, 86, 86, 0.5)

$photo-text: #C6C6C6

$nav-link-hover: #4E4E4E

$dark-bg: #131313
$main-bg: #303030

$grey-border: #363636
$green-border: #DCCBA7
$main-color: #DACAA4

$substrate-dark-rgba: rgba(0, 0, 0, .13)
$substrate-dark: #353535 
$substrate-light-rgba: rgba(255, 255, 255, .5)
$case-substrate: #2B2B2B

$main-color-dark: #303030
$main-color-dark-2: #212121
$secondary-color: #DACAA4
$text-color-accent: #E9E9E9
$text-color-secondary: #A4A4A4
$person-1-color: #675656
$person-2-color: #464A59
$person-3-color: #474D4B
$dark_substr: #282828



