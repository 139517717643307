.cases
  display: flex
  justify-content: flex-end
  padding-top: toRem(207)
  padding-bottom: toRem(305)

  .base-headline
    margin-bottom: toRem(9)

  .base-title
    margin-bottom: toRem(72)

  &__block
    width: toRem(831)

  .case-card
    display: flex
    justify-content: space-between
    align-items: flex-start
    padding: toRem(49) toRem(113) toRem(59) toRem(21)
    min-height: toRem(172)
    cursor: unset
    margin-bottom: toRem(27)
    background-color: rgba(0, 0, 0, 0.13)
    transition: background-color .2s ease-in-out

    //&:hover
    //  background-color: rgba(0, 0, 0, 0.43)
    //
    //  .base-caption-2
    //    color: $text-color-accent
    //    line-height: 152%

    &:nth-child(1)
      .case-card__img
        margin-top: toRem(12)
        margin-left: toRem(22)
        width: toRem(117)

    &:nth-child(2)
      .case-card__img
        margin-top: toRem(-1)
        margin-left: toRem(26)
        width: toRem(164)

    &:nth-child(3)
      .case-card__img
        margin-top: toRem(8)
        margin-left: toRem(27)
        width: toRem(183)

    &:nth-child(4)
      .case-card__img
        margin-top: toRem(9)
        margin-left: toRem(22)
        width: toRem(216)

    &:nth-child(5)
      .case-card__img
        margin-top: toRem(-27)
        margin-left: toRem(0)
        width: toRem(174)

    &:nth-child(6)
      .case-card__img
        margin-top: toRem(0)
        margin-left: toRem(22)
        width: toRem(132)

    &:last-child
      margin-bottom: 0

    .base-caption-2
      width: toRem(335)
      line-height: 152%
      text-align: left
      transition: color .2s ease-in-out