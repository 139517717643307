@mixin font($font_name, $file_name, $weight, $style)
  @font-face
    font-family: $font_name
    font-display: swap
    src: url("../../public/fonts/#{$file_name}.woff") format("woff"), url("../../public/fonts/#{$file_name}.ttf") format("truetype")
    font-weight: $weight
    font-style: $style

@mixin titleFont()
  font-family: 'Montserrat', sans-serif

@mixin largeTitle()
  @include titleFont()
  font-size: toRem(63)
  font-weight: 700
  line-height: 127%
  color: $text-color-accent

@mixin mediumTitle()
  @include titleFont()
  font-size: toRem(40)
  font-weight: 700
  line-height: 160%
  color: $text-color-accent

@mixin smallTitle()
  @include titleFont()
  font-size: toRem(30)
  font-weight: 700
  line-height: 213%
  color: $text-color-accent

@mixin headlineBold()
  font-family: 'Inter-Bold', sans-serif
  font-size: toRem(18)
  font-weight: 700
  line-height: 222%
  letter-spacing: .03em
  text-transform: uppercase
  color: $text-color-secondary

@mixin captionSmall()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(14)
  font-weight: 500
  line-height: 171%
  color: $text-color-secondary

@mixin captionMedium()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(32)
  font-weight: 500
  line-height: 175%
  color: $text-color-secondary

@mixin captionLarge()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(26)
  line-height: 188%
  font-weight: 500
  color: $text-color-accent

@mixin caption2Medium()
  font-family: 'Inter-Medium', sans-serif
  font-size: toRem(21)
  font-weight: 500
  line-height: 214%
  color: $text-color-secondary

